import React, {useState} from "react";
 import {contentfulGQLClient} from "../utils/contentful";
import {Box, Text} from "native-base";
import {gql, useQuery} from "@apollo/client";

export function Banner() {
    const [bannerData, setBannerData] = useState();

    useQuery(gql`
        query bannerCollection {
            bannerCollection {
                __typename
                items {
                    show
                    message
                    backgroundColor
                    textColor
                    __typename
                }
            }
        }
    `, {
        fetchPolicy: 'cache-first',
        client: contentfulGQLClient,
        onCompleted: (data) => {
            const banner = data?.bannerCollection?.items?.[0];

            if (banner && banner?.show) {
                setBannerData(banner);
            }
        }
    })

    if (!bannerData) return null;

    return (
        <Box p={2} bg={bannerData?.backgroundColor}>
            <Text textAlign={'center'} color={bannerData?.textColor} fontSize={'md'}
                  fontWeight={600}>{bannerData?.message}</Text>
        </Box>
    )
}
