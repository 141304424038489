import React from 'react';
import { Box, Text, Checkbox, Select, VStack } from 'native-base';
import { useRecoilState } from 'recoil';
import { devState } from '../state/devState';

export function DevSwitcher() {
  const [state, setState] = useRecoilState(devState);

  const onSetBrand = (brand: string) => {
    setState((existingState) => {
      return { ...existingState, brand };
    });

    window.document.location = '/';
  };

  const onSetDev = (dev: boolean) => {
    setState((existingState) => {
      return { ...existingState, dev };
    });

    window.location.reload();
  };

  return (
    <>
      {!state.dev && (
        <Text color={'white'} position={'fixed'} left={0} top={0} bg={'red.500'} px={8} py={2}>
          Production
        </Text>
      )}
      <VStack space={2} position={'fixed'} left={2} bottom={2} bg={'gray.100'} p={2}>
        <Select selectedValue={state.brand} onValueChange={onSetBrand}>
          <Select.Item label="Hey Fella" value={'heyfella'} />
          <Select.Item label="Leaf Doctors" value={'leafdoctors'} />
          <Select.Item label="True Green" value={'truegreen'} />
          <Select.Item label="Online Doctor" value={'onlinedoctor'} />
          <Select.Item label="Doctor Portal" value={'portal'} />
        </Select>

        <Checkbox value={'dev'} isChecked={state.dev} onChange={(value) => onSetDev(value)}>
          <Text fontWeight={600} fontSize={'xs'} color={'black'}>
            Dev Environment
          </Text>
        </Checkbox>
      </VStack>
    </>
  );
}
