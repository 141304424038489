import { useAuth0 } from '@auth0/auth0-react';
import { useCallback, useEffect, useState } from 'react';
import { useCustomer } from './useCustomer';

export type feature =
  | 'requests'
  | 'customers'
  | 'scheduling'
  | 'appointments'
  | 'messaging'
  | 'documents'
  | 'treatmentplan'
  | 'notes'
  | 'prescriptions'
  | 'pathology'
  | 'delivery'
  | 'fulfill';

const supportFeatures: feature[] = ['customers', 'appointments', 'requests', 'prescriptions'];

export const usePortalPermissions = () => {
  const { getAccessTokenSilently, isLoading, isAuthenticated } = useAuth0();

  const [permissions, setPermissions] = useState<string[]>([]);
  const [brands, setBrands] = useState<string[]>([]);

  const parseJwt = (token: string) => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join(''),
    );

    return JSON.parse(jsonPayload);
  };

  useEffect(() => {
    getAccessTokenSilently()
      .then((token) => {
        const payload = parseJwt(token);

        setBrands((payload?.['onlinedoctor/brands'] ?? [])?.sort((a, b) => a?.localeCompare(b)));
        setPermissions(payload?.permissions ?? []);
      })
      .catch(null);
  }, []);

  const hasAnyPermissions = useCallback(() => {
    return permissions.length > 0;
  }, [permissions]);

  const { isDoctor } = useCustomer();

  const hasPermission = useCallback(
    (feature: feature) => {
      if (permissions.includes('onlinedoctor.internal')) return true;

      if (permissions.includes('onlinedoctor.fulfill')) {
        return feature === 'fulfill';
      }

      if (permissions.includes('onlinedoctor.support')) {
        return supportFeatures.includes(feature);
      }

      return false;
    },
    [permissions],
  );

  return { hasPermission, isDoctor, hasAnyPermissions, isAuthenticated, isLoading, brands };
};
